import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: UserSelections = null;

export default handleActions<UserSelections, any>({

  [Actions.CHANGE_OWNERSHIP_SUCCESS]: (state, action) => {
    const { questionIdentifierAnswersRemoved } = action.payload;
    if (questionIdentifierAnswersRemoved && questionIdentifierAnswersRemoved.length > 0) {
      return state.filter(w => questionIdentifierAnswersRemoved.indexOf(w.identifier) == -1 );
    }

    return state;
  },

  [Actions.QUESTION_OPTION_PRESSED]: (state, action) => {
    const { rowId, identifier, value } = action.payload as UserSelection;

    var userInput = state.find(input => input.identifier == identifier && input.rowId == rowId);

    if (userInput == null) {
      return [...state, {
        identifier: identifier,
        rowId: rowId,
        value: value,
        saving: true
      } as UserSelection];
    }

    return state.map(input => {
      if (input.rowId == rowId && input.identifier == identifier) {
        return {...input,
          value: value,
          tempValue: input.value,
          deleteAnswerUrl: null,
          saving: true
        };
      }

      return input;
    });
  },

  [Actions.QUESTION_OPTION_PRESSED_ERROR]: (state, action) => {
    const { rowId, identifier } = action.payload as UserSelection;

    return state.map(input => {
      if (input.rowId == rowId && input.identifier == identifier) {
        return {...input,
          value: input.tempValue,
          tempValue: null,
          saving: false
        };
      }

      return input;
    });
  },

  [Actions.QUESTION_OPTION_PRESSED_CONFIRMED]: (state, action) => {
    const { rowId, identifier, deleteAnswerUrl } = action.payload.userSelection as UserSelection;

    return state.map(input => {
      if (input.rowId == rowId && input.identifier == identifier) {
        return {...input,
          tempValue: null,
          deleteAnswerUrl,
          saving: false
        };
      }

      return input;
    });
  },


}, initialState);
